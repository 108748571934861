export const environment = {
  firebase: {
    apiKey: 'AIzaSyBiWCpAw8COj9poGCRhpgPuVSqjj3p31xw',
    authDomain: 'vagus-tech-demo.firebaseapp.com',
    projectId: 'vagus-tech-demo',
    storageBucket: 'vagus-tech-demo.appspot.com',
    messagingSenderId: '319255884959',
    appId: '1:319255884959:web:b740f5f69d2cc6beb69eaa',
    measurementId: 'G-33C29KKPFR',
    rechaptchaToken: '6LerGWIoAAAAAMvMmjb-v6GKlZYWPkrt01CEnblJ',
    appCheckDebugToken: false,
  },
  version: require('../../package.json').version,
  production: true,
  useEmulators: false,
  name: 'demo',
  sentryDsn:
    'https://1f8d94fc366741aab4be6798d614eb7f@o4505522962759680.ingest.sentry.io/4505523940491264',
  apiUrl: 'https://demo.finapsys.cl/api',
};
